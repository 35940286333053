.loginleft {
    /* background: #012982; */
    text-align: center;
    height: 100vh;
    position: relative;
    /* background-image: linear-gradient(135deg, #1a1a2e, #343b6b, #1a1a2e); */
    background-image: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
}

.loginleft .loginlogo {
    position: absolute;
    top: 43%;
    left: 0;
    right: 0;
    margin-top: -50px;
    width: 300px;
    display: inline-block;
    margin: 0 auto;
}
.loginright {
    padding: 30px 0;
}
.loginright h3 {
    font-style: 24px;
    color: #012982;
}
.loginright .btn-default img {
    float: left;
    margin-right: 10px;
    
  
}
.loginlogo{
    /* filter: brightness(0) invert(1); */
}
.loginright .btn-default {
    text-align: left;
    border: 1px #ddd solid;
    color: #666;
}
.loginright .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
}
.sign{
    border-radius: 10px !important;
    padding: 8px 15px;
}
@media (max-width: 767px){
.loginleft {
    height: auto;
    padding: 30px 0;
}
.loginleft .loginlogo {
    position: static;}
}