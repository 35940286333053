.hero-wrap{
    width: 100%;
    height: 700px;
    position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100vh;
    background-position: 50% 9.6px;
}
.container {
    max-width: 1140px;
}

.height-show{
    height: 600px; 
}

.slider-text .subheading {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 600;
}
.slider-text h1 {
    font-size: 64px;
    color: #000000;
    line-height: 1;
    font-weight: 700;
}
.slider-text p {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}
.btn.btn-primary {
    background: #207dff;
    border: 1px solid #207dff !important;
    color: #fff !important;
}
.btn {
    cursor: pointer;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
}
/* @media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 992px)
{
    .container {
        max-width: 960px;
    }
}



@media (min-width: 576px)
{
    .container {
        max-width: 540px;
    }
} */

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
 @media (max-width: 768px){
    .hero-wrap{
        width: 100%;
        height: 700px;
        position: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        
        background-position: 40% 9.6px;
    }
} 

.contentText{
    color: white !important;
}